import React from 'react'

import styled from '@emotion/styled'
import { Input, Button, Typography } from '@wicadu/arepa/ui'

import useTemplate from '@hooks/useTemplate'

const texts = {
  title: 'Restablecer contraseña',
  description: 'Para continuar, es necesario que ingrese una nueva contraseña',
  password_placeholder: 'Ingresar contraseña',
  auth_button_recover: 'Confirmar'
}

function ResetPasswordTemplate () {
  const { onSubmit } = useTemplate()

  return (
    <Wrapper className='row center-xs middle-sm'>
      <div className='col-xs-12 col-sm-12 col-md-4 col-lg-3'>
        <WrapperForm>
          <Typography type='title-4' weight={700}>{texts.title}</Typography>
          <Typography type='description'>{texts.description}</Typography>

          <ContentForm>
            <Input
              placeholder={texts.password_placeholder}
              name='password'
              htmlType='password'
              size='medium'
              fullWidth
            />
            <Button onClick={onSubmit} size='medium' fullWidth>{texts.auth_button_recover}</Button>
          </ContentForm>
        </WrapperForm>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & {
    margin: 0;

    input {
      margin-bottom: 16px;
    }
  }
`

const WrapperForm = styled.div`
  margin: 75px 10px; 
`

const ContentForm = styled.div`
  margin-top: 10px;
`

export default ResetPasswordTemplate

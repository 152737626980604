import React, { useCallback, useEffect } from 'react'

import { navigate } from 'gatsby'
import { Form } from '@wicadu/arepa/ui'

import { useMutation } from '@apollo/client'
// import { RESET_PASSWORD } from '@mutations/resetPassword'
const RESET_PASSWORD = ''
import { ResetPasswordResponse } from '@ts-types/ResetPasswordResponse'

import { TemplateProvider } from '@hooks/useTemplate'
import matchGraphQLErrors from '@utils/matchGraphQLErrors'
import getURLSearchParams from '@utils/getURLSearchParams'
import ResetPasswordTemplate from '@components/templates/ResetPassword'

function ResetPasswordContainer() {
  const { handleSubmit, setValue } = Form.useForm()

  const redirectToInvalidToken = useCallback(() => {
    navigate('/invalid-token', { replace: true })
  }, [])

  const onCompleted = useCallback((): void => {
    navigate('/password-reset', { replace: true })
  }, [])

  const onError = useCallback(
    ({ graphQLErrors, networkError }: any) => {
      if (networkError) return console.log('networkErrorAlert')

      if (matchGraphQLErrors(graphQLErrors, 'invalid-token')) return redirectToInvalidToken()

      navigate('/error')
    },
    [redirectToInvalidToken]
  )

  const [resetPassword] = useMutation<ResetPasswordResponse>(RESET_PASSWORD, {
    onCompleted,
    onError,
  })

  useEffect(() => {
    (() => {
      const urlParams: URLSearchParams = getURLSearchParams()

      const tokenId: string = urlParams.get('tokenId')

      if (!tokenId) return redirectToInvalidToken()

      const token: string = urlParams.get('token')

      if (!token) return redirectToInvalidToken()

      setValue('tokenId', tokenId)
      setValue('token', token)
    })()
  }, [])

  const onSubmit = useCallback(
    handleSubmit(({ tokenId, token, password }) => {
      resetPassword({
        variables: {
          input: {
            tokenId,
            token,
            password,
          },
        },
      })
    }),
    [handleSubmit, resetPassword]
  )

  return (
    <TemplateProvider value={{ onSubmit }}>
      <ResetPasswordTemplate />
    </TemplateProvider>
  )
}

export default ResetPasswordContainer

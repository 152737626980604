import React, { Fragment } from 'react'
import SEO from '@components/SEO'
import ResetPasswordContainer from '@containers/ResetPassword'
import ResetPasswordForm from '@HOCs/ResetPasswordForm'

function ResetPassword () {
  return (
    <Fragment>
      <SEO title='Restablecer contraseña' />

      <ResetPasswordForm>
        <ResetPasswordContainer />
      </ResetPasswordForm>
    </Fragment>
  )
}

export default ResetPassword

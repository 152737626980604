import React, { useMemo, useEffect } from 'react'
import { Form } from '@wicadu/arepa/ui'
import { yup, yupResolver } from '@wicadu/arepa/utils'
import PropTypes, { InferProps } from 'prop-types'

const propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func
}

const defaultProps = {
  onSubmit () {}
}

type Props = InferProps<typeof propTypes>

const schema = yup.object().shape({
  password: (
    yup.string()
      .min(7, 'Minimo 7 carácteres')
      .max(50, 'Máximo 50 carácteres')
      .required('El campo es requerido')
  )
  /*repeatPassword: (
    yup.string()
      .min(7, 'Minimo 7 carácteres')
      .max(50, 'Máximo 50 carácteres')
      .required('El campo es requerido')
  )*/
})

const VirtualInputs = () => {
  const { register } = Form.useForm()

  useEffect(() => {
    register('tokenId')
    register('token')
  }, [])

  return null
}

function ResetPasswordForm ({ children, onSubmit }: Props) {
  const opts = useMemo(() => ({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  }), [])

  return (
    <Form opts={opts} onSubmit={onSubmit}>
      <VirtualInputs />
      {children}
    </Form>
  )
}

ResetPasswordForm.propTypes = propTypes
ResetPasswordForm.defaultProps = defaultProps

export default ResetPasswordForm
